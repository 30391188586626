<template>
  <div
    class="top"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <el-row :gutter="10" id="rowMax">
      <el-col :span="15"
        ><div class="grid-content bg-purple">
          <!--客服坐席统计-->
          <customerservice ref="customerservices"></customerservice></div
      ></el-col>
      <el-col :span="11"
        ><div class="grid-content bg-purple">
          <!--通话数据统计-->
          <conversation ref="conversations"></conversation></div
      ></el-col>
    </el-row>
    <el-row :gutter="10">
      <!--呼叫服务曲线-排队统计-->
      <el-col :span="15"><diagram ref="diagrams"></diagram></el-col>
      <el-col :span="11"><Qstatistics ref="Qstatisticss"></Qstatistics></el-col>
    </el-row>
  </div>
</template>

<script>
import customerservice from "./customerService.vue";
import conversation from "./conversation.vue";
import diagram from "./diagram.vue";
import { apiShout } from "../../api1/configShout";
import Qstatistics from "./Qstatistics.vue";
export default {
  name: "serviceLook",
  components: { customerservice, conversation, diagram, Qstatistics },
  inject:['reload'],
  data() {
    return {
      clientHeight: document.body.clientHeight,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      formData: {},
      oID: "",
      timer: "",
    };
  },
  created() {
    // this.getInfos();
    let aa = JSON.parse(localStorage.getItem("loginuser"));
    this.oID = aa.orgId;
    this.getList();
  },
  mounted() {
    console.log('0000=')
    this.timer = setInterval(this.getList, 1000);
    this.$nextTick(function (){
      window.onresize = () => {
      console.log('000010001')
      return (() => {
        const that = this;
        that.$nextTick(function () {
          console.log('1231231231------')
          // window.fullHeight = document.documentElement.clientHeight;
          window.fullWidth = document.documentElement.clientWidth;
          // that.windowHeight = window.fullHeight; // 高
          that.windowWidth = window.fullWidth; // 宽
        });
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
        that.clientHeight = document.documentElement.clientHeight;
      })();
    };
    })
  },
  watch: {
    // windowHeight(val) {
    //   console.log('123123')
    //   let that = this;
    //   console.log("实时屏幕高度222：", val, that.windowHeight);
    //   this.$router.go(0);
    // },
    windowWidth(val) {
      console.log('333')
      let that = this;
      console.log("实时屏幕宽度2222：", val, that.windowHeight);
      this.$router.go(0);
    },
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    getList() {
      apiShout.getcStatistic({ orgId: this.oID }).then((res) => {
        console.log("数据：", res.data);
        this.formData = res.data.result;
        this.$nextTick(() => {
          this.$refs.customerservices.getInfo(this.formData);
          this.$refs.conversations.getInfo(this.formData);
          this.$refs.diagrams.getInfo(this.formData);
          this.$refs.Qstatisticss.getInfo(this.formData);
        });
      });
    },
    // getInfos() {
    //   apiShout.services({ cno: 2001, orgId: 1 }).then((res) => {
    //     this.formData = res.data.result;
    //     this.$nextTick(()=>{
    //       this.$refs.customerservices.getInfo(this.formData)
    //       this.$refs.conversations.getInfo(this.formData)
    //       this.$refs.diagrams.getInfo(this.formData)
    //     })
    //   });
    // },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang='less' scoped>
.top {
  // width: 1125px;
  // height: auto;
  // margin-left: 30px;
  overflow-x: hidden;
}
.el-row {
  margin: 25px 0 25px 25px;
  margin-left: 0px !important;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
}
.grid-content {
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
}
/deep/.bg-num p {
  margin-bottom: 0px;
}
</style>