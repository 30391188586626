<template>
  <div>
    <el-row id="rowMax">
      <el-col :span="24"
        ><div class="grid-content bg-title" style="padding: 0px 25px">
          <p class="max_title">呼叫服务曲线</p>
        </div>
      </el-col>
    </el-row>
    <div class="content">
      <el-row :gutter="10" class="row_two">
        <div
          id="main"
          style="
            width: 100%;
            height: 190px;
            border: 1px solid #e4e7ed;
            font-weight: bold;
            box-shadow: 0px 0px 2px -1px;
            border-radius: 12px;
          "
        ></div>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "conversation",
  data() {
    return {
      charts: "",
      opinion: [], //第二条线的选点位置
      opinionDatas: [], //底部坐标时间
      opinionData: [], //第一条线的选点位置
    };
  },
  methods: {
    drawLine(id) {
      this.charts = this.$echarts.init(document.getElementById(id));
      this.charts.setOption({
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["通话量", "呼损量"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.opinionDatas, //底部坐标时间
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "通话量",
            type: "line",
            stack: "总量",
            data: this.opinionData, //第一条线选点
          },
          {
            name: "呼损量",
            type: "line",
            stack: "总量2",
            data: this.opinion, //第二条线选点
          },
        ],
      });
    },
    getInfo(data) {
      console.log(data, "输出查看数据显示");
      this.opinionData = Object.values(data.callConnectCurve);
      this.opinion = Object.values(data.callLossCurve);
      this.opinionDatas = Object.keys(data.callConnectCurve);
      this.drawLine("main");
    },
  },
  //调用
  mounted() {
    this.$nextTick(function () {
      this.drawLine("main");
    });
  },
};
</script>

<style lang='less' scoped>
.el-row {
  margin-bottom: 0px;
  margin-left: 0px !important;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
}
.grid-content {
  min-height: 36px;
}
.content {
  padding: 0px 15px;
}
.row_two {
  display: flex;
  margin-bottom: 20px;
}
.row-bg {
  padding: 10px 0;
}
.bg-text {
  text-align: center;
  line-height: 36px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  color: #92aca7;
}
.max_title {
  font-size: 20px;
  font-weight: bold;
  color: #92aca7;
  margin-bottom: 2%;
}
</style>