<template>
  <div>
    <el-row>
      <el-col :span="24"
        ><div class="grid-content bg-purple-dark" style="padding: 0px 25px">
          <p class="max_title">客服坐席统计</p>
        </div></el-col
      >
    </el-row>
    <div class="content">
      <el-row :gutter="10" class="row_two">
        <el-col :span="9"
          ><div class="grid-content bg-purple" :style="note">
            <div class="grid-content bg-text">客服专员</div>
            <div class="grid-content bg-text">
              <div class="bg-num">
                <p>{{ list.leave }}</p>
                <p>离开</p>
              </div>
              <div class="bg-num">
                <p>{{ list.busy }}</p>
                <p>忙碌</p>
              </div>
              <div class="bg-num">
                <p>{{ list.free }}</p>
                <p>空闲</p>
              </div>
            </div>
          </div></el-col
        >
        <el-col :span="9"
          ><div class="grid-content bg-purple" :style="note">
            <div class="grid-content bg-text">外呼专员</div>
            <div class="grid-content bg-text">
              <div class="bg-num">
                <p>{{ listTwo.leave }}</p>
                <p>离开</p>
              </div>
              <div class="bg-num">
                <p>{{ listTwo.busy }}</p>
                <p>忙碌</p>
              </div>
              <div class="bg-num">
                <p>{{ listTwo.free }}</p>
                <p>空闲</p>
              </div>
            </div>
          </div></el-col
        >
        <el-col :span="9"
          ><div class="grid-content bg-purple" :style="note">
            <div class="grid-content bg-text">400专员</div>
            <div class="grid-content bg-text">
              <div class="bg-num">
                <p>{{ listThree.leave }}</p>
                <p>离开</p>
              </div>
              <div class="bg-num">
                <p>{{ listThree.busy }}</p>
                <p>忙碌</p>
              </div>
              <div class="bg-num">
                <p>{{ listThree.free }}</p>
                <p>空闲</p>
              </div>
            </div>
          </div></el-col
        >
      </el-row>
      <el-table
        :data="tableData"
        style="
          width: 100%;
          border: 1px solid #e4e7ed;
          font-weight: bold;
          box-shadow: 0px 0px 2px -1px;
          border-radius: 12px;
        "
        height="250"
      >
        <el-table-column prop="serviceType" label="类别" width="104">
        </el-table-column>
        <el-table-column prop="workNumber" label="工号" width="104">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="104">
        </el-table-column>
        <el-table-column prop="callCount" label="通话数量" width="104">
        </el-table-column>
        <el-table-column prop="statusTime" label="时长" width="104">
        </el-table-column>
        <el-table-column prop="status" label="状态" width="104">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "customerService",
  data() {
    return {
      tableData: [],
      list: [{}],
      listTwo: [{}],
      listThree: [{}],
      note: {
        backgroundImage: "url(" + require("../../assets/service.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 117px",
        // backgroundSize: "100%",
        padding: "8px 16px 10px 5px",
      },
    };
  },
  methods: {
    getInfo(data) {
      this.tableData = data.userList;
      this.tableData.forEach((ele) => {
        // console.log('ele:',ele);
        if (ele.statusTime == null) {
          ele.statusTime = "--";
        }
        if (ele.callCount == null) {
          ele.callCount = 0;
        }
      });
      this.list = data.customerService;
      this.listTwo = data.outboundService;
      this.listThree = data.fourHundredService;
    },
  },
};
</script>

<style lang='less' scoped>
.el-row {
  margin-bottom: 0px;
  margin-left: 0px !important;
  &:last-child {
    margin-bottom: 0;
  }
}
.grid-content {
  min-height: 36px;
}
.content {
  padding: 0px 15px;
  display: grid;
}
.row_two {
  display: flex;
  margin-bottom: 20px;
}
.row-bg {
  padding: 10px 0;
}
.bg-text {
  text-align: center;
  line-height: 26px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  color: #fff;
}
.max_title {
  font-size: 20px;
  font-weight: bold;
  color: #92aca7;
}
/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none !important;
  text-align: center;
}
/deep/.el-table th.el-table__cell > .cell {
  color: #92aca7;
  text-align: center;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>