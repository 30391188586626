<template>
  <div>
    <el-row>
      <el-col :span="24"
        ><div class="grid-content bg-purple-dark" style="padding: 0px 25px">
          <p class="max_title">排队统计</p>
        </div></el-col
      >
    </el-row>
    <!-- <el-row id="rowMax">
      <el-col :span="24"
        ><div class="grid-content bg-title"> -->
    <!-- <p class="max_title">排队统计</p> -->
    <div class="content">
    <div class="contents">
      <el-row :gutter="10" class="row_two">
        <el-col :span="9"
          ><div class="grid-content bg-purple">
            <div class="grid-content bg-text">排队人数</div>
            <div class="grid-content bg-text" style="margin-bottom: 12px">
              {{ list.lineUpCount }}
            </div>
          </div></el-col
        >
        <el-col :span="9"
          ><div class="grid-content bg-purple">
            <div class="grid-content bg-text">最长排队时长</div>
            <div class="grid-content bg-text" style="margin-bottom: 12px">
              {{ list.lineUpMaxTime }}
            </div>
          </div></el-col
        >
        <el-col :span="9"
          ><div class="grid-content bg-purple">
            <div class="grid-content bg-text">平均排队时长</div>
            <div class="grid-content bg-text" style="margin-bottom: 12px">
              {{ list.lineUpAvgTime }}
            </div>
          </div></el-col
        >
      </el-row>
    </div>

    <div class="contents">
      <el-row :gutter="10" class="row_two">
        <el-col :span="13"
          ><div class="grid-content bg-purple">
            <div class="grid-content bg-text">紧急求助</div>
            <div class="grid-content bg-text" style="margin-bottom: 12px">
              <router-link
                :to="{ path: '../../../call_center/seekhelp' }"
                style="color: #92aca7"
                >{{ list.alarmHelpHisCount }}</router-link
              >
            </div>
          </div></el-col
        >
        <el-col :span="14"
          ><div class="grid-content bg-purple">
            <div class="grid-content bg-text">待办工单</div>
            <div class="grid-content bg-text" style="margin-bottom: 12px">
              <router-link
                :to="{ path: '../../../call_center/agency' }"
                style="color: #92aca7"
                >{{ list.workOrderCount }}</router-link
              >
            </div>
          </div></el-col
        >
      </el-row>
    </div>
    </div>
    <!-- </div></el-col
      >
    </el-row> -->
  </div>
</template>

<script>
export default {
  name: "conversation",
  data() {
    return {
      list: {},
    };
  },
  methods: {
    getInfo(data) {
      console.log(data, "输出查看数据显示");
      this.list = data;
    },
  },
};
</script>

<style lang='less' scoped>
.el-row {
  margin-bottom: 0px;
  margin-left: 0px !important;
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
}
.grid-content {
  min-height: 36px;
}
.contents {
  width: 100%;
  padding: 0px 15px;
  border: 1px solid #e4e7ed;
  font-weight: bold;
  box-shadow: 0px 0px 2px -1px;
  border-radius: 12px;
  margin-bottom: 12px;
}
.content{
    padding: 0 15px;
}
.row_two {
  display: flex;
  margin-bottom: 20px;
}
.row-bg {
  padding: 10px 0;
}
.bg-text {
  text-align: center;
  line-height: 36px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  color: #92aca7;
}
.max_title {
  font-size: 20px;
  font-weight: bold;
  color: #92aca7;
  margin-bottom: 2%;
}
</style>