<template>
  <div>
    <el-row>
      <el-col :span="24"
        ><div class="grid-content bg-purple-dark" style="padding: 0px 25px">
          <p class="max_title">通话数据统计</p>
        </div></el-col
      >
    </el-row>
    <div class="content">
      <el-row :gutter="10" class="row_two">
        <el-col :span="9"
          ><div class="grid-content bg-purple" :style="note">
            <div class="grid-content bg-text" style="margin-bottom: 12px">
              {{ list.callNumber }}
            </div>
            <div class="grid-content bg-text">通话次数</div>
          </div></el-col
        >
        <el-col :span="9"
          ><div class="grid-content bg-purple" :style="note">
            <div class="grid-content bg-text" style="margin-bottom: 12px">
              {{ list.callTime }}
            </div>
            <div class="grid-content bg-text">通话时长</div>
          </div></el-col
        >
        <el-col :span="9"
          ><div class="grid-content bg-purple" :style="note">
            <router-link :to="{ path: './callLoss' }">
              <div class="grid-content bg-text" style="margin-bottom: 12px">
                {{ list.callLoss }}
              </div>
              <div class="grid-content bg-text">呼损数</div></router-link
            >
          </div></el-col
        >
      </el-row>
      <el-table
        :data="tableData"
        style="
          width: 100%;
          border: 1px solid #e4e7ed;
          font-weight: bold;
          box-shadow: 0px 0px 2px -1px;
          border-radius: 12px;
        "
        height="250"
      >
        <el-table-column prop="name" label="通话类别" width="145">
        </el-table-column>
        <el-table-column prop="count" label="通话次数" width="145">
        </el-table-column>
        <el-table-column prop="time" label="通话时长" width="145">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "conversation",
  data() {
    return {
      tableData: [{}],
      list: {},
      note: {
        backgroundImage: "url(" + require("../../assets/service.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 113px",
        padding: "8px 7px 8px 5px",
      },
    };
  },
  methods: {
    getInfo(data) {
      this.tableData = data.typeList;
      this.list = data;
      if (this.list.callTime == null) {
        this.list.callTime = 0;
      }
    },
  },
};
</script>

<style lang='less' scoped>
.el-row {
  margin-bottom: 0px;
  margin-left: 0px !important;
  &:last-child {
    margin-bottom: 0;
  }
}
.grid-content {
  min-height: 36px;
}
.content {
  padding: 0px 15px;
  display: grid;
}
.row_two {
  display: flex;
  margin-bottom: 22px;
}
.row-bg {
  padding: 10px 0;
}
.bg-text {
  text-align: center;
  line-height: 26px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  color: #fff;
}
.max_title {
  font-size: 20px;
  font-weight: bold;
  color: #92aca7;
}
/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none !important;
  text-align: center;
}
/deep/.el-table th.el-table__cell > .cell {
  color: #92aca7;
  text-align: center;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>